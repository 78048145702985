import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TitleStrategy } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeEsMX from '@angular/common/locales/es-MX';
registerLocaleData(localeEsMX);

import { PageTitleStrategy } from '@modules/core/resolvers/page-title.resolver';

@NgModule({
  declarations: [],
  imports: [CommonModule, BrowserModule, BrowserAnimationsModule, HttpClientModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-MX',
    },
  ],
})
export class CoreModule {}
