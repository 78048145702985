import { Component, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cargamos-root',
  template: `
    <ng-progress [spinner]="false" [color]="'var(--cds-primary-color)'"></ng-progress>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly swUpdates: SwUpdate = inject(SwUpdate);

  constructor() {
    this.setupLanguage();
    this.swUpdates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => this.reloadApp());
  }

  private setupLanguage(): void {
    const defaultLanguage: string = 'es';
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
  }

  private reloadApp(): void {
    document.location.reload();
  }
}
