import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@modules/main-layout/main-layout.module').then(m => m.MainLayoutModule),
  },
  {
    path: 'tracking',
    loadChildren: () => import('@modules/tracking/tracking.module').then(m => m.TrackingModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
